<template>
  <div
    v-if="dhmeProjectAssemblyPlanningStatus === 'success'"
    class="d-flex flex-column flex-scroll-width"
  >
    <module-navigation-bar title="Project assembly planning">
      <template slot="module-nav-logo">
        <img
            v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div
      class="ant-glass-background d-flex radius-0 ant-border-bottom align-center px-2"
      style="height: 70px"
    >
      <div class="d-flex c-pointer">
        <div class="mx-2">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div style="font-size: 14px" v-on="on">
                <v-icon class="mr-1" dense> mdi-factory</v-icon>
                {{ dhmeProjectAssemblyHall?.hall ?? '-' }}
              </div>
            </template>
            <span>Hall</span>
          </v-tooltip>
        </div>
        <div class="mx-2">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-cog</v-icon>
            </template>
            <span>Configuration</span>
          </v-tooltip>
          {{ dhmeProjectAssemblyHallConfiguration?.title ?? '-' }}
        </div>
        <div class="mx-2">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div style="font-size: 14px" v-on="on">
                <v-icon class="mr-1" dense>
                  mdi-clock-time-seven-outline
                </v-icon>
                {{ dhmeProjectAssemblyHallTime ?? '-' }}
              </div>
            </template>
            <span>{{ assemblyStartDate }}</span>
          </v-tooltip>
        </div>
        <div class="mx-2">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <div style="font-size: 14px" v-on="on">
                <v-icon class="mr-1" dense> mdi-speedometer</v-icon>
                {{ dhmeProjectAssemblyHallSpeed ?? '-' }}
              </div>
            </template>
            <span>Workload</span>
          </v-tooltip>
        </div>
      </div>

      <v-spacer />
      <v-select
        v-model="highlightedModuleType"
        :items="moduleTypes"
        class="mx-5"
        clearable
        dense
        filled
        hide-details
        placeholder="Type"
        prepend-inner-icon="mdi-format-color-highlight"
        style="max-width: 200px"
      />
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn icon tile v-on="tooltip" @click="sendRequestPlanning">
            <v-icon small>mdi-calendar-clock</v-icon>
          </v-btn>
        </template>
        <span>{{ text('submitRequest') }}</span>
      </v-tooltip>
      <v-chip
        v-if="dhmeProjectAssemblyPhase?.task_type?.custom_1 === 'request'"
        color="warning"
        >Submitted</v-chip
      >
      <v-chip
        v-if="dhmeProjectAssemblyPhase?.task_type?.custom_1 === 'planned'"
        color="info"
        >Planned</v-chip
      >
      <v-chip
        v-if="dhmeProjectAssemblyPhase?.task_type?.custom_1 === 'rejected'"
        color="error"
        >Rejected</v-chip
      >
    </div>
    <div
      v-if="dhmeProjectAssemblyTasksStatus === 'success' || isInitialized"
      class="d-flex flex-column flex-1 flex-scroll-width overflow-x-auto"
    >
      <dhme-project-assembly-planning-automated
        v-if="dhmeProjectAssemblyHall?.automated === 1"
        :highlighted-module-type="highlightedModuleType"
      />
      <dhme-project-assembly-planning-manual
        v-else-if="dhmeProjectAssemblyHall?.automated === 0"
        :highlighted-module-type="highlightedModuleType"
      />
      <div v-else class="d-flex align-center justify-center font-italic flex-1">
        You can request a planning in the top right
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center flex-1">
      <ant-loading />
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center">
    <ant-loading />
  </div>
</template>

<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { DHME_PROJECT_ASSEMBLY_PLANNING } from '@/modules/modules';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import moment from 'moment';
import DhmeProjectAssemblyPlanningAutomated from '@/modules/daiwa-house-modular-europe/ProjectAssemblyPlanning/DhmeProjectAssemblyPlanningAutomated.vue';
import DhmeProjectAssemblyPlanningManual from '@/modules/daiwa-house-modular-europe/ProjectAssemblyPlanning/DhmeProjectAssemblyPlanningManual.vue';
import { importTasksV2 } from '@/services/api/v2/tasks.v2.api';

export default {
  name: 'DHMEProjectAssemblyPlanning',
  components: {
    DhmeProjectAssemblyPlanningManual,
    DhmeProjectAssemblyPlanningAutomated,
    AntLoading,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      isInitialized: false,
      locationTypeFilter: {},
      highlightedModuleType: '',
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeProjectAssemblyPlanningStatus',
      'dhmeProjectAssemblyTasks',
      'dhmeProjectAssemblyTasksStatus',
      'dhmeProjectAssemblyPhase',
      'dhmeProjectAssemblyHall',
      'dhmeProjectAssemblyHallSpeed',
      'dhmeProjectAssemblyHallTime',
      'dhmeProjectAssemblyHallConfiguration',
      'dhmeProjectAssemblyHallLocations',
      'dhmeProjectAssemblyPlanningAssemblyHallConfigurations',
      'dhmeProjectAssemblyPlanningModules',
      'dhmeProjectAssemblyPlanningCalendar',
      'selectedLicense',
      'project',
      'authenticatedUser',
        'isDaiwaLicense'
    ]),
    assemblyStartDate() {
      if (this.dhmeProjectAssemblyPhase) {
        return moment(this.dhmeProjectAssemblyPhase.planned_start)
          .clone()
          .set({
            hour: this.dhmeProjectAssemblyHallTime,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format('YYYY-MM-DD HH:mm');
      }

      return moment().format('YYYY-MM-DD HH:MM');
    },
    moduleTypes() {
      return [
        ...new Set(
          this.dhmeProjectAssemblyPlanningModules.map((m) => m.module_type)
        ),
      ];
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchProjectAssemblyPlanningData', {
      projectId: this.project.id,
      moduleId: this.project.modules.find(
        (module) => module.route === DHME_PROJECT_ASSEMBLY_PLANNING
      ).id,
      sessionId: this.$route.params.sessionId ?? null,
    });
    await this.$store.dispatch('fetchDhmeAssemblyPlanningTasks');
    this.isInitialized = true;
  },
  methods: {
    async sendRequestPlanning() {
      let data = [
        {
          id: this.dhmeProjectAssemblyPhase.id,
          options: {
            custom_1: 'request',
          },
        },
      ];

      const tasks = await importTasksV2(data);

      this.$store.commit('dhme_assembly_tasks_update', tasks);

      this.$store.commit('showNotification', {
        content: 'Planning request submitted',
        color: 'success',
      });
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.projectAssemblyPlanning.${path}`, params);
    },
  },
};
</script>

<style lang="scss" scoped></style>
